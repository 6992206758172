<template>
  <div class="main">
    <div id="screen">
      <div class="screen-video-container">
        <video class="screen-video" data-object-fit="cover" playsinline="" autoplay="" muted="" loop="" poster="images/poster.jpg">
          <source :src="videoUrl" type="video/mp4" />
        </video>
        <div class="screen-pattern"></div>
      </div>
      <div class="screen-text">
        <!-- <h2>
          <img src="images/main_title.png" alt="" class="img-responsive" />
        </h2> -->
        <div class="btn-wrap is-desktop">
          <a href="https://maitreyatemplebundle.s3.ap-northeast-2.amazonaws.com/build/MaitreyaTempleSetup.exe" class="btns"
            ><img src="images/btn/btn_window_down2.png" alt="WINDOW 버전 다운로드" style="margin-bottom: 20px; width:300px;"/></a
          ><br />
          <template v-if="isLogin">
            <a href="#" class="btn_box" @click.prevent="btnPlay()" v-if="isLogin"
              ><img src="images/btn/btn_start3.png" alt="미륵사 시작하기" style="margin-bottom: 20px; width:300px;"/></a
            ><br />
          </template>
          <!-- <a href="#" class="btns" @click="btnBroadcast()"
              ><img src="images/btn/btn_cast_down3.png" alt="방송하기 다운로드" style="margin-bottom: 20px; width:300px;"
            /></a>
            <a href="https://maitreyatemplebundle.s3.ap-northeast-2.amazonaws.com/build/MaitreyaTvSetup.exe" class="inbox_link">설치파일 받기</a> -->
          <a href="#" class="btn_box" @click.prevent="btnBroadcast()"
            ><img src="images/btn/btn_cast_down3.png" alt="방송하기 다운로드" style="margin-bottom: 20px; width:300px;" />
            <a href="#" @click.stop="downTv()" class="inbox_link">설치파일 받기</a>
          </a>
        </div>
        <div class="is-desktop">
          <!-- <div class="btn-wrap-right" v-if="isLogin">
            <a href="#" class="btns" @click="btnBroadcast"><img src="images/btn/btn_cast_down_v2.png" alt="방송하기 방송하기" style="margin-bottom: 10px;"/></a
            ><br />
            <a href="#" class="btns" @click="btnPlay"><img src="images/btn/btn_play.png" alt="WINDOW 버전 실행하기"/></a>
          </div> -->
          <div class="btn-wrap-right">
            <a href="https://play.google.com/store/apps/details?id=com.futureworld4.maitreyatemple" target="_blank" class="btns"
              ><img src="images/btn/btn_google_play.png" alt="PLAY STORE DOWNLOAD" style="width:200px;margin-bottom: 5px;"/></a
            ><br />
            <a href="https://apps.apple.com/kr/app/미륵사-가상-사찰-불교-체험/id1547748029" target="_blank" class="btns"
              ><img src="images/btn/btn_app_store.png" alt="APP STORE DOWNLOAD" style="width:200px;"
            /></a>
          </div>
        </div>

        <div class="is-mobile">
          <div class="btn-wrapt">
            <a href="https://cafe.naver.com/maitreyatemple/" target="_blank" class="btns"
              ><img src="images/btn/cafe_btn.png" alt="카페 바로가기" style="width:200px;margin-bottom: 10px;"
            /></a>
            <a href="https://play.google.com/store/apps/details?id=com.futureworld4.maitreyatemple" target="_blank" class="btns"
              ><img src="images/btn/btn_google_play.png" alt="PLAY STORE DOWNLOAD" style="width:200px; margin-bottom: 10px;"
            /></a>
            <a href="https://apps.apple.com/kr/app/미륵사-가상-사찰-불교-체험/id1547748029" target="_blank" class="btns"
              ><img src="images/btn/btn_app_store.png" alt="APP STORE DOWNLOAD" style="width:200px; margin-bottom: 10px;"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      videoUrl: 'https://maitreyatemplebundle.s3.ap-northeast-2.amazonaws.com/intro.mp4',
    }
  },
  computed: {
    ...mapGetters(['isLogin', 'User']),
  },
  created() {
    const videoUrl = this.$PUB?.site?.web_bg_media
    if (videoUrl) this.videoUrl = videoUrl
  },
  methods: {
    btnPlay() {
      let pubtoken = localStorage.getItem('pubtoken')
      if (!pubtoken) return alert('로그인하세요.')
      let exportUrl = `maitreyatemple://?token=${pubtoken}`

      window.location.href = exportUrl
    },
    btnBroadcast() {
      let pubtoken = localStorage.getItem('pubtoken')
      // if (!pubtoken) return alert('로그인하세요.')
      let exportUrl = `maitreyatv://?token=${pubtoken}`

      window.location.href = exportUrl
    },
    btnDown(kind) {
      console.log('TODO:: 다운로드 링크')
      let linkUrl = this.$PUB.download.windows
      switch (kind) {
        case 'apk':
          linkUrl = this.$PUB.download.android
          break
        case 'ios':
          linkUrl = this.$PUB.download.iphone
          break
        default:
          break
      }
      window.location.href = linkUrl
    },
    downTv() {
      const linkUrl = 'https://maitreyatemplebundle.s3.ap-northeast-2.amazonaws.com/build/MaitreyaTvSetup.exe'
      window.location.href = linkUrl
    },
  },
}
</script>

<style lang="scss">
[class^='section_'] {
  min-height: 100px;
}
.section_1 {
  height: 350px;
}
.btn-wrap-right {
  text-align: right;
}

.is-desktop {
  display: block;
}
.is-mobile {
  display: none;
}

.btn_box {
  position: relative;
  .inbox_link {
    position: absolute;
    right: 127px;
    top: 50px;
    color: #cccccc;
    text-decoration: underline;
  }
  .inbox_link:hover {
    color: white;
  }
}

#screen .screen-text {
  margin-top: 150px;
}

@media (max-width: 480px) {
  .is-desktop {
    display: none;
  }
  .is-mobile {
    display: block;
  }
  #screen .screen-text {
    margin-top: 45px;
  }
}
</style>
